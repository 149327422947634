import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/brand-marketing-content-saas.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function ContentPlan({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="SaaS Brand, Marketing and Content Strategy - Template" 
       description="This 29-page document includes templates to help you align your brand, marketing and content strategy, and tell a coherent story."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/saas-brand-marketing-strategy-template/">SaaS Brand, Marketing and Content Strategy - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="SaaS Brand, Marketing and Content Strategy - Template" />
            <SubHeading subheading="This 29-page document includes templates to help you align your brand, marketing and content strategy, and tell a coherent story." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/saas-brand-marketing-content-strategy" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="SaaS Brand, Marketing and Content Strategy - Template" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
            <TextMedium
                textMedium="We’ve used the templates in this document to develop and implement marketing, brand, 
                and content strategies for SaaS companies of various sizes." /> 
            <TextMedium textMedium="Some were early-stage startups with no real brand and no content in place - with them, 
                we started by covering the business and brand foundations and continued with 
                simplified versions of the marketing and content strategies."/> 
            <TextMedium textMedium="With scale-ups and large enterprises, we usually start by reviewing the existing documentation 
                and making sure that the brand, marketing and content strategies are aligned to the 
                overarching business strategy. Once those are in place, we fill in the gaps and develop 
                the missing parts."/>
            <GumroadForm 
                  formName="SaaS Brand, Marketing and Content Strategy"
                  redirectPage="https://socontent.gumroad.com/l/saas-brand-marketing-content-strategy"
                  buttonText="Get it on Gumroad"
                  buttonLink="https://socontent.gumroad.com/l/saas-brand-marketing-content-strategy"
                  // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
              />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentPlan
